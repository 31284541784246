a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }

.container {
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgb(13, 12, 14);
  }
  
  .header {
    position: relative;
    font-size: 5vw;
    background-color: rgb(13, 12, 14);
    z-index: 10;
    color: whitesmoke
  }
  
  .prefix {
    position: absolute;
    left: 0;
    z-index: -1;
    transform: translate(0%);
    opacity: 0;
    transition: transform 250ms, opacity 200ms; ;
    color: whitesmoke
  }
  
  .header:hover .prefix {
    opacity: 1;
    transform: translate(-100%);
  }
  
  .suffix {
    position: absolute;
    right: 0;
    z-index: -1;
    transform: translate(0%);
    opacity: 0;
    transition: transform 250ms, opacity 200ms; ;
    width: max-content;
    color: whitesmoke
  }
  
  .header:hover .suffix {
    opacity: 1;
    transform: translate(100%);
  }
