.App {
  text-align: center;
  background-color: rgb(13, 12, 14);
  min-height: 100vh;
}

.App-header {
  background-color: rgb(13, 12, 14);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header {
  color: white
}

#root {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: clip;
}

.embedcontainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5em
}

.textcontainer {
  display: grid;
  text-align: left;
  grid-template-columns: 1fr;
  gap: 5em;
  padding-right: 10vw;
  padding-left: 10vw;
}

.footercontainer {
  display: grid;
  text-align: centre;
  grid-template-columns: 1fr;
  gap: 5em;
  padding-right: 10vw;
  padding-left: 10vw;
}

@media only screen and (max-width: 940px)  {
  .embedcontainer {grid-template-columns: 1fr;}
}
